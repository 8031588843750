import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'address',
  template: `    
    <div [formGroup]="address" class="address">
      <h4>Adres: <span><button mat-flat-button color="warn" *ngIf="addressIndex > 0 && !childIsMember" type="button"
                               (click)="removeAddress(addressIndex)">Verwijder adres</button></span></h4>
      <mat-form-field>
        <mat-label>Straat</mat-label>
        <input matInput placeholder="Straat" formControlName="street" required [readonly]="childIsMember">
      </mat-form-field>
      <mat-form-field>
        <mat-label>huisnummer en bus</mat-label>
        <input matInput placeholder="Huisnummer en bus" formControlName="houseNumber" required [readonly]="childIsMember">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Postcode</mat-label>
        <input matInput placeholder="Postcode" formControlName="postalCode" required [readonly]="childIsMember">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Gemeente</mat-label>
        <input matInput placeholder="Gemeente" formControlName="city" required [readonly]="childIsMember">
      </mat-form-field>
        </div>
  `,
  styleUrls: ['addresses.component.scss']
})
export class AddressComponent {

  @Input()
  address: FormGroup;

  @Input()
  addressIndex: number;

  @Input()
  childIsMember: boolean;

  @Output()
  addressRemoved: EventEmitter<number> = new EventEmitter();

  removeAddress(addressIndex: number) {
    this.addressRemoved.emit(addressIndex)
  }
}
