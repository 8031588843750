import * as moment from 'moment';

export class DatePatterns {
  static getCorrectDatePattern(date: any) {
    console.log('setting date when saving')
    console.log(date)

    date = moment(date).format('DD-MM-YYYY');

    console.log('after save')
    console.log(date)

    return date;
  }
}
