import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from '../../models/account';
import {AccountService} from '../../services/account/account.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {MessagesService} from '../../services/errorMessage/messages.service';
import {DatePatterns} from "../../models/DatePatterns";
import {Router} from "@angular/router";

@Component({
  selector: 'overview-form',
  template: `
    <h1>U bent correct geregistreerd!</h1>
    <h2>U ontvangt hiervoor een bevestigingsmail. <br>
      Het inschrijven van uw kinderen kan u dagelijks doen op Saenhoeve zelf.</h2>
    <h2>Overzicht van uw opgeslagen gegevens</h2>
      <mat-card class="my-card">
        <mat-card-content>
          <mat-card-content>
            <form [formGroup]="updateForm">
                <div>
                    <mat-form-field>
                      <mat-label>Gebruikersnaam/email</mat-label>
                      <input matInput placeholder="Gebruikersnaam/email" formControlName="username" required>
                    </mat-form-field>
                    <a mat-raised-button color="primary" href="javascript:void(0);" (click)="navigateTo('/wachtwoord')">Wachtwoord aanpassen</a>
                </div>
              <parents [registerForm]="updateForm" [parentsData]="account.parents"></parents>
              <children [registerForm]="updateForm" [childrenData]="account.children"></children>

              <mat-checkbox checked="{{true}}" disabled class="terms-conditions">Door mijn kinderen in te schrijven ga ik akkoord met <a href="https://www.saenhoeve.be/" target="_blank">voorwaarden op de website</a>!</mat-checkbox>
            </form>
          </mat-card-content>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="primary" (click)="update()" color="primary">Sla op</button>
        </mat-card-actions>
      </mat-card>
  `,
  styleUrls: ['./overview.component.scss']
})
export class OverviewFormComponent implements OnInit {

  @Input()
  account: Account;

  @Output()
  accountUpdated: EventEmitter<boolean> = new EventEmitter();

  updateForm: FormGroup;

  constructor(private readonly accountService: AccountService, private readonly formBuilder: FormBuilder, private _snackBar: MatSnackBar, private readonly messagesService: MessagesService, private router: Router) {
  }

  ngOnInit() {
    this.updateForm = this.formBuilder.group({
      username: this.account.user.username
    });
  }

  navigateTo(value: string) {
    this.router.navigate([value])
  }

  update() {
    if (this.updateForm.invalid) {
      this.messagesService.showInvalidForm()
      return;
    }

    const updatedAccount = this.getUpdatedAccount();

    const resultRef = this.accountService.updateAccount(updatedAccount).subscribe(
      response => {
        this.handleActivationResponse(response);
        resultRef.unsubscribe();
      },
      error => this.handleError(error)
    );
  }

  private getUpdatedAccount(): Account {
    const updatedAccount = this.updateForm.value;
    updatedAccount.id = this.account.id;
    this.syncUserId(updatedAccount);
    this.formatDates(updatedAccount);
    this.syncChildIds(updatedAccount);
    this.syncParentIds(updatedAccount);

    return updatedAccount;
  }

  private syncUserId(updatedAccount) {
    updatedAccount.user = {
      id: this.account.user.id,
      roles: this.account.user.roles,
      username: this.updateForm.value.username,
    };
  }

  private syncParentIds(updatedAccount) {
    this.account.parents.forEach((parent, index) => {
      if (updatedAccount.parents[index]) {
        updatedAccount.parents[index].id = parent.id;
      }
    });
  }

  private syncChildIds(updatedAccount) {
    this.account.children.forEach((child, index) => {
      if (updatedAccount.children[index]) {
        updatedAccount.children[index].id = child.id;
        updatedAccount.children[index].ageGroup = child.ageGroup;
        this.syncAddressIds(child, updatedAccount, index);
      }
    });
  }

  private syncAddressIds(child, updatedAccount, index) {
    child.addresses.forEach((address, indexAddress) => {
      if (updatedAccount.children[index].addresses[indexAddress]) {
        updatedAccount.children[index].addresses[indexAddress].id = address.id;
      }
    });
  }

  private formatDates(updatedAccount) {
    updatedAccount.children.forEach(child => {
      child.dateOfBirth = DatePatterns.getCorrectDatePattern(child.dateOfBirth);
    });
  }

  private handleActivationResponse(response: any) {
    this.messagesService.showSucces();
    this.accountUpdated.emit(true);
  }

  private handleError(error: any) {
    this.messagesService.getAndShowErrorMessage(error);
  }
}
