import {Component} from "@angular/core";
import {Router} from "@angular/router";

@Component({
  selector: 'no-more-registration',
  template: `
    <div>
      <h1>Helaas</h1>
      <p>De speelpleinzomer komt weer op zijn eind. De Laatste week kunnen <strong>geen nieuwe registraties of activaties van registraties</strong> meer gebeuren. Hopelijk zien we jullie volgende zomer opnieuw!</p>
    </div>
  `,
  styleUrls: ['./no-more-registration.component.scss']
})
export class NoMoreRegistrationComponent {

  constructor(private router: Router) {
  }
}
