import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {Token} from "../../models/token";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class AuthenticationService {

  private readonly url: string;

  constructor(private readonly http: HttpClient, @Inject(DOCUMENT) private document: Document) {
    this.url = '/login-user';
  }

  login(username: string, password: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<Token>(this.url, JSON.stringify({username: username, password: password}), httpOptions)
      .pipe(
        map(json => {
          sessionStorage.setItem('token', json.token);

          return true;
        }),
        catchError(error => {
          return throwError(false);
        })
      );
  }

  public logout() {
    sessionStorage.removeItem('token');

    this.document.location.href = '/logout';
  }

  public isLogin(): boolean {
    if (this.getToken() != '') {
      return true;
    }

    return false;
  }

  private getToken(): string {
    const token = sessionStorage.getItem('token');

    return token ? token : '';
  }
}
