import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {OverviewComponent} from './overview.component';
import {AccountService} from '../../services/account/account.service';
import {HttpClientModule} from '@angular/common/http';
import {MaterialModule} from '../material/material.module';
import {RegistrationModule} from '../registration/registration.module';
import {ReactiveFormsModule} from '@angular/forms';
import {OverviewFormComponent} from './overview-form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {PasswordModule} from "../password/password.module";
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MaterialModule,
    RegistrationModule,
    ReactiveFormsModule,
    PasswordModule,
    MatCheckboxModule
  ],
  providers: [
    AccountService
  ],
  declarations: [
    OverviewComponent,
    OverviewFormComponent
  ],
  exports: [
    OverviewComponent
  ]
})
export class OverviewModule {}
