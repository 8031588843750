import {Component, Input, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {Child} from "../../models/child";
import {MealPreference} from "../../models/MealPreference";

@Component({
  selector: 'children',
  template: `
    <div [formGroup]="registerForm">
      <div formArrayName="children"
           *ngFor="let child of registerForm.get('children')['controls']; let i = index;">
        <div class="person" [formGroupName]="i">
         <child [child]="child" [childIndex]="i" [childData]="getChildData(i)" (childRemoved)="removeChild($event)"></child>
        </div>
      </div>
      <button mat-raised-button type="button" class="add-child-button" (click)="addChild()">Extra kind toevoegen</button>
    </div>
  `,
  styleUrls: ['children.component.scss']
})
export class ChildrenComponent implements OnInit {

  @Input()
  registerForm: FormGroup;

  @Input()
  childrenData: Child[];

  children: FormArray;

  constructor(private readonly formBuilder: FormBuilder) {
  }

  ngOnInit() {
    if (this.childrenData && this.childrenData.length > 0) {
      this.registerForm.addControl('children', this.formBuilder.array(this.createExistingChildren(this.childrenData)));
    } else {
      this.registerForm.addControl('children', this.formBuilder.array([this.createChild()]));
    }
    this.children = this.registerForm.get('children') as FormArray;
  }

  createChild(): FormGroup {
    return this.formBuilder.group({
      firstName: '',
      lastName: '',
      email: '',
      familyDoctor: '',
      dateOfBirth: '',
      mealPreference: 'NO_PREFERENCE',
      tuition: '',
      allergiesNote: '',
      medicalNote: '',
      nationalRegistryNumber: ''
    })
  }

  addChild(): void {
    this.children = this.registerForm.get('children') as FormArray;
    this.children.push(this.createChild());
  }

  removeChild(index: number): void {
    this.children.removeAt(index);
  }

  private createExistingChildren(children: Child[]): FormGroup[] {
    let groupArray: FormGroup[] = [];

    children.forEach(child => {
      groupArray.push(this.createExistingChild(child));
    });

    return groupArray;
  }

  private createExistingChild(child: Child): FormGroup {
    return this.formBuilder.group({
      firstName: child.firstName,
      lastName: child.lastName,
      email: child.email,
      familyDoctor: child.familyDoctor,
      dateOfBirth: child.dateOfBirth,
      mealPreference: child.mealPreference,
      tuition: child.tuition,
      allergiesNote: child.allergiesNote,
      medicalNote: child.medicalNote,
      nationalRegistryNumber: child.nationalRegistryNumber
    })
  }

  getChildData(index: number): Child {
    if (this.childrenData) {
      return this.childrenData[index];
    }

    return undefined;
  }
}
