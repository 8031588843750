import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OverviewComponent} from './modules/overview/overview.component';
import {AuthGuard} from './guards/auth.guard';
import {LoginComponent} from './modules/login/login.component';
import {RegistrationComponent} from './modules/registration/registration.component';
import {ResetPasswordComponent} from './modules/password/reset-password.component';
import {NoMoreRegistrationComponent} from "./modules/noMoreRegistration/no-more-registration.component";
import {ErrorComponent} from "./modules/error/error.component";

const routes: Routes = [
  // {
  //   path: '',
  //   component: LoginComponent
  // },
  // {
  //   path: 'overzicht',
  //   component: OverviewComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'wachtwoord',
  //   component: ResetPasswordComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'registreer',
  //   component: RegistrationComponent
  // },
  // {
  //   path: 'login',
  //   redirectTo: ''
  // },
  // {
  //   path: 'error',
  //   component: ErrorComponent
  // },
  // {
  //   path: 'account-beheer/registreer',
  //   pathMatch: 'full',
  //   component: LoginComponent
  // },
  // next lines are needed to handle child routes that may exists on old QR-codes or flyers
  // using the url inschrijvingen.saenhoeve.be/account-beheer/registreer
  {
    path: 'account-beheer',
    loadChildren: () => import('./account-beheer-routing.module').then(m => m.AccountBeheerRoutingModule)
  },
  {
    path: '',
    component: NoMoreRegistrationComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
