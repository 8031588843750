import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'login',
  template: `
    <mat-card>
      <mat-card-content>
        <form>
          <mat-form-field>
            <input matInput placeholder="Gebruikersnaam" [(ngModel)]="username" name="username" required>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Wachtwoord" [(ngModel)]="password" type="password" name="password" required>
          </mat-form-field>
            <mat-label *ngIf="wrongCredentials">
                Foutieve gebruikersnaam of wachtwoord. Indien u uw wachtwoord niet meer weet kan u dit laten aanpassen op Saenhoeve
            </mat-label>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button [disabled]="loading" mat-raised-button (click)="login()" color="primary">Aanmelden</button>
      </mat-card-actions>
		<mat-label>
        Nog geen account? Klik dan <a href="javascript:void(0);" (click)="navigateTo('/registreer')">hier</a> om u te registreren.
		</mat-label>
    </mat-card>
  `,
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;

  loading = false;
  wrongCredentials = false;

  constructor(private router: Router, private authenticationService: AuthenticationService, private _snackBar: MatSnackBar) {
  }

  ngOnInit() : void {
    if (this.authenticationService.isLogin()) {
      this.router.navigate(['/overzicht']);
    }
  }

  navigateTo(value: string) {
    this.router.navigate([value])
  }

  login() : void {
    this.loading = true;
    this.wrongCredentials = false;

    this.authenticationService.login(this.username, this.password)
      .subscribe(data => {
        this.router.navigate(['/overzicht']);
      }, error => {
        this.loading = false;
        this.wrongCredentials = true;

        this.router.navigate(['/login']);
      });
  }
}
