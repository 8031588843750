import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'error',
  template: `
    <div>Er liep iets mis. Log je opnieuw in of registreer</div>
    <login></login>
  `,
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  username: string;
  password: string;

  loading = false;
  wrongCredentials = false;

  constructor(private router: Router, private authenticationService: AuthenticationService, private _snackBar: MatSnackBar) {
  }

  ngOnInit() : void {
    if (this.authenticationService.isLogin()) {
      this.router.navigate(['/overzicht']);
    }
  }

  navigateTo(value: string) {
    this.router.navigate([value])
  }

  login() : void {
    this.loading = true;
    this.wrongCredentials = false;

    this.authenticationService.login(this.username, this.password)
      .subscribe(data => {
        this.router.navigate(['/overzicht']);
      }, error => {
        this.loading = false;
        this.wrongCredentials = true;

        this.router.navigate(['/login']);
      });
  }
}
