import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {OverviewModule} from './modules/overview/overview.module';
import {UserService} from './services/user/user.service';
import {AuthGuard} from './guards/auth.guard';
import {LoginModule} from './modules/login/login.module';
import {MaterialModule} from './modules/material/material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegistrationModule} from './modules/registration/registration.module';
import {MessagesService} from './services/errorMessage/messages.service';
import {PasswordModule} from './modules/password/password.module';
import {NoMoreRegistrationModule} from "./modules/noMoreRegistration/no-more-registration.module";
import {ErrorModule} from "./modules/error/error.module";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    LoginModule,
    OverviewModule,
    RegistrationModule,
    PasswordModule,
    NoMoreRegistrationModule,
    ErrorModule
  ],
  providers: [
    UserService,
    MessagesService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
