import {Component, Input, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {Address} from "../../models/address";

@Component({
  selector: 'addresses',
  template: `    
    <div [formGroup]="child">
          <div formArrayName="addresses"
               *ngFor="let address of child['controls'].addresses['controls']; let k=index">
            <div [formGroupName]="k">
             <address [address]="address" [childIsMember]="childIsMember" [addressIndex]="k" (addressRemoved)="removeAddress($event)"></address>
            </div>
          </div>
          <button *ngIf="!childIsMember" mat-stroked-button type="button" (click)="addAddress()">Extra adres voor dit kind toevoegen
          </button>
        </div>
  `,
  styleUrls: ['addresses.component.scss']
})
export class AddressesComponent implements OnInit {

  @Input()
  child: FormGroup;

  @Input()
  addressesData: Address[];

  @Input()
  childIsMember: boolean;

  addresses: FormArray;

  constructor(private readonly formBuilder: FormBuilder) {
  }

  ngOnInit() {
    if (this.addressesData && this.addressesData.length > 0) {
      this.child.addControl('addresses', this.formBuilder.array(this.createExistingAddresses(this.addressesData)));
    } else {
      this.child.addControl('addresses', this.formBuilder.array([this.createAddress()]));
    }
  }

  createAddress(): FormGroup {
    return this.formBuilder.group({
      street: '',
      houseNumber: '',
      postalCode: '',
      city: ''
    })
  }

  addAddress(): void {
    this.addresses = this.child.get('addresses') as FormArray;
    this.addresses.push(this.createAddress());
  }

  removeAddress(addressIndex: number) {
    this.addresses = this.child.get('addresses') as FormArray;
    this.addresses.removeAt(addressIndex);
  }

  private createExistingAddresses(addresses: Address[]) {
    let groupArray: FormGroup[] = [];

    addresses.forEach(address => {
      groupArray.push(this.createExistingAddress(address));
    });
    return groupArray;
  }

  private createExistingAddress(address: Address) {
    return this.formBuilder.group({
      street: address.street,
      houseNumber: address.houseNumber,
      postalCode: address.postalCode,
      city: address.city
    });
  }
}
