import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {User} from "../../models/user";

@Injectable()
export class UserService {

  constructor(private httpClient: HttpClient) {
  }

  public currentUser(): Observable<User> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    });

    return this.httpClient.get<User>('/api/user/current', {headers: headers});
  }

  public updatePassword(userid: number, newPassword: string): Observable<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    });
    const body = {
      password: newPassword
    };

    return this.httpClient.put<User>(`/api/user/${userid}/password`, body, {headers});
  }
}
