import {Injectable} from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar';
import {connectableObservableDescriptor} from "rxjs/internal/observable/ConnectableObservable";

@Injectable()
export class MessagesService {

  constructor(private _snackBar: MatSnackBar) {
  }

  showInvalidForm(): void {
    this._snackBar.open('Niet alle velden zijn correct ingevuld', 'ok', {
      duration: 5000,
    });
  }

  showSucces(): void {
    this._snackBar.open('Uw gegevens zijn succesvol opgeslagen', 'ok', {
      duration: 5000,
    });
  }

  showRegistrationSucces(): void {
    this._snackBar.open('Uw gegevens zijn succesvol opgeslagen. Let op: dit is slechts een registratie van uw gegevens. Het inschrijven zelf kan u dagelijks doen op Saenhoeve zelf bij onze inschrijvers. Tot snel!', 'ok', {
      duration: 50000,
    });
  }

  getAndShowErrorMessage(error: any): void {
    if (error.error.error.includes('Foutmelding')) {
      this._snackBar.open(error.error.error, 'ok', {
        duration: 5000,
      });
    } else {
      this._snackBar.open('Er liep iets mis, probeer het later opnieuw', 'ok', {
        duration: 5000,
      });
    }
  }
}
