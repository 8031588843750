import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Account} from '../../models/account';
import {AccountService} from '../../services/account/account.service';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'overview',
  template: `
    <ng-container *ngIf="account$ | async as account else loading">
      <overview-form *ngIf="!account.user.passwordReset" [account]="account" (accountUpdated)="reload()"></overview-form>
        <reset-password *ngIf="account.user.passwordReset" [account]="account" [resetted]="true" (resetSucces)="reload()"></reset-password>
    </ng-container>
   <ng-template #loading>
     Uw gegevens worden geladen...
   </ng-template>
    <button (click)="logout()" mat-raised-button color="primary">Afmelden</button>
  `,
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  account$: Observable<Account>;

  constructor(private readonly accountService: AccountService, private readonly authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.account$ = this.accountService.getLoggedInAccount();
  }

  logout(): void {
    this.authenticationService.logout();
  }

  reload() {
    this.ngOnInit();
  }
}
