import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AccountService} from "../../services/account/account.service";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'parent',
  template: `
    <div [formGroup]="parent" class="parent">
      <div class="parent-header">
        <h3>Gegevens Ouder: </h3>
        <button mat-flat-button color="warn" type="button" *ngIf="parentIndex > 0"
                      (click)="removeParent(parentIndex)">Verwijder</button>
      </div>
      <div class="parent-content">
        <mat-form-field>
          <mat-label>Voornaam</mat-label>
          <input matInput placeholder="Voornaam" formControlName="firstName" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Achternaam</mat-label>
          <input matInput placeholder="Achternaam" formControlName="lastName" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>GSM</mat-label>
          <input matInput placeholder="GSM" formControlName="cellphoneNumber" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Relatie tot kind</mat-label>
          <input matInput placeholder="Relatie tot kind" formControlName="relationToChild" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Rijksregisternummer</mat-label>
          <input matInput placeholder="LET OP: indien u uw kosten wilt inbrengen aan de belastingen MOET u dit veld invullen. Dit kan enkel voor de ouder bij wie dit kind ten laste is."
                 formControlName="nationalRegistryNumber">
        </mat-form-field>
      </div>
    </div>
  `,
  styleUrls: ['parents.component.scss']
})
export class ParentComponent {

  @Input()
  parent: FormGroup;

  @Input()
  parentIndex: number;

  @Output()
  parentRemoved: EventEmitter<number> = new EventEmitter();

  removeParent(index: number) {
    this.parentRemoved.emit(index)
  }
}
