import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {UserService} from "../services/user/user.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) {
  }

  canActivate(): Observable<boolean> {
    return this.userService.currentUser()
      .pipe(
        map(user => {
          return true;
        }),
        catchError(error => {
          this.router.navigate(['/login']);

          return throwError(false);
        })
      )
  }
}
