import { NgModule } from '@angular/core';
import {RegistrationComponent} from './registration.component';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {AccountService} from '../../services/account/account.service';
import {MaterialModule} from '../material/material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ParentsComponent} from '../parents/parents.component';
import {ParentComponent} from '../parents/parent.component';
import {ChildrenComponent} from '../children/children.component';
import {ChildComponent} from '../children/child.component';
import {AddressesComponent} from '../addresses/addresses.component';
import {AddressComponent} from '../addresses/address.component';
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		MaterialModule,
		ReactiveFormsModule,
		MatCheckboxModule
	],
  providers: [
    AccountService
  ],
  declarations: [
    RegistrationComponent,
    ParentsComponent,
    ParentComponent,
    ChildrenComponent,
    ChildComponent,
    AddressesComponent,
    AddressComponent
  ],
  exports: [
    RegistrationComponent,
    ParentComponent,
    ParentsComponent,
    ChildrenComponent
  ]
})
export class RegistrationModule { }
