import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Account} from '../../models/account';
import {AccountService} from '../../services/account/account.service';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MessagesService} from '../../services/errorMessage/messages.service';
import {UserService} from '../../services/user/user.service';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'reset-password',
  template: `
	  <h2>Wachtwoord veranderen</h2>
    <p *ngIf="resetted">U heeft uw wachtwoord laten resetten op Saenhoeve. Daarom moet u nu eerst uw wachtwoord zelf terug instellen in een zelf gekozen wachtwoord.</p>
	  <ng-container *ngIf="account$ | async as account else loading">
      <mat-card class="my-card">
        <mat-card-content>
          <mat-card-content>
            <form [formGroup]="passwordForm">
              <mat-form-field appearance="fill">
                <mat-label>Nieuw wachtwoord</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Wachtwoord" formControlName="password" (input)="onPasswordInput()" required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="password.hasError('required')">Wachtwoord is verplicht</mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Herhaal nieuw wachtwoord</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Herhaal wachtwoord" formControlName="password2" (input)="onPasswordInput()" required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="password2.hasError('required')">Herhaal je nieuw wachtwoord</mat-error>
                <mat-error *ngIf="password2.invalid && !password2.hasError('required')">Wachtwoorden zijn niet gelijk</mat-error>
              </mat-form-field>
            </form>
          </mat-card-content>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button color="primary" (click)="update(account)">Sla op</button>
        </mat-card-actions>
      </mat-card>
    </ng-container>
    <ng-template #loading>
        gegevens ophalen...
    </ng-template>
  `,
  styleUrls: ['./password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @Input()
  account: Account;

  @Input()
  resetted: boolean;

  @Output()
  resetSucces = new EventEmitter<boolean>();

  account$: Observable<Account>;
  passwordForm: FormGroup;
  hide = true;

  constructor(private readonly accountService: AccountService, private readonly formBuilder: FormBuilder, private _snackBar: MatSnackBar,
              private readonly messagesService: MessagesService, private readonly userService: UserService, private readonly router: Router) {
  }

  ngOnInit() {
    this.account$ = this.account ? of(this.account) : this.accountService.getLoggedInAccount();
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      password2: ['', [Validators.required]]
    }, {validator: passwordMatchValidator});
  }

  update(account: Account) {
    if (this.passwordForm.invalid) {
      this.messagesService.showInvalidForm();

      return;
    }

    const resultRef = this.userService.updatePassword(account.user.id, this.password.value).subscribe(
      response => {
        this.handleActivationResponse(response);
        resultRef.unsubscribe();
      },
      error => this.handleError(error)
    );
  }

  private handleActivationResponse(response: any) {
    this.messagesService.showSucces();
    this.resetSucces.emit(true);
    this.router.navigate(['/overzicht']);
  }

  private handleError(error: any) {
    this.messagesService.getAndShowErrorMessage(error);
  }

  get password() { return this.passwordForm.get('password'); }
  get password2() { return this.passwordForm.get('password2'); }

  onPasswordInput() {
    this.password2.setErrors(this.passwordForm.hasError('passwordMismatch') ? [{passwordMismatch: true}] : null );
  }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  return formGroup.get('password').value === formGroup.get('password2').value ? null : {passwordMismatch: true};
};
