import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {ErrorComponent} from "./error.component";
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {AppRoutingModule} from "../../app-routing.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LoginModule} from "../login/login.module";

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    LoginModule,
  ],
  providers: [
    AuthenticationService
  ],
  declarations: [
    ErrorComponent,
  ],
  exports: [
    ErrorComponent
  ]
})
export class ErrorModule {}
