import {Component} from '@angular/core';
import {AuthenticationService} from './services/authentication/authentication.service';

@Component({
  selector: 'app-root',
  template: `
    <mat-toolbar color="primary">
      <mat-toolbar-row>
		    <a mat-button routerLink="/overzicht" *ngIf="isLoggedIn()">Overzicht</a>
        <a mat-button routerLink="/registreer" *ngIf="!isLoggedIn()">Registreer</a>
        <a mat-button routerLink="/login" *ngIf="!isLoggedIn()">Log in</a>
        <a mat-button routerLink="/logout"  *ngIf="isLoggedIn()" (click)="logout()">Afmelden</a>
      </mat-toolbar-row>
    </mat-toolbar>
    <div id="main">
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private readonly authenticationService: AuthenticationService) {
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isLogin();
  }

  logout(): void {
    this.authenticationService.logout();
  }
}
