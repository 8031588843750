import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from '../../services/account/account.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Parent} from '../../models/parent';

@Component({
  selector: 'parents',
  template: `
    <div [formGroup]="registerForm">
      <div formArrayName="parents" class="parents"
           *ngFor="let parent of registerForm.get('parents')['controls']; let j = index">
        <div class="person" [formGroupName]="j">
            <parent [parent]="parent" [parentIndex]="j" (parentRemoved)="removeParent($event)"></parent>
        </div>
      </div>
      <button class="add-parent" mat-raised-button type="button" (click)="addParent()">Extra ouder toevoegen</button>
    </div>
  `,
  styleUrls: ['parents.component.scss']
})
export class ParentsComponent implements OnInit {

  @Input()
  registerForm: FormGroup;

  @Input()
  parentsData: Parent[];

  parents: FormArray;

  constructor(private readonly accountService: AccountService, private readonly formBuilder: FormBuilder) {
  }

  ngOnInit() {
    if (this.parentsData && this.parentsData.length > 0 ) {
      this.registerForm.addControl('parents', this.formBuilder.array(this.createExistingParents(this.parentsData)));
    } else {
      this.registerForm.addControl('parents', this.formBuilder.array([this.createEmptyParent()]))
    }
    this.parents = this.registerForm.get('parents') as FormArray;
  }

  createExistingParents(parents: Parent[]): FormGroup[] {
    const groupArray: FormGroup[] = [];

    parents.forEach(parent => {
      groupArray.push(this.createExistingParent(parent));
    });

    return groupArray;
  }

  createExistingParent(parent: Parent): FormGroup {
    return this.formBuilder.group({
      firstName: parent.firstName,
      lastName: parent.lastName,
      email: parent.email,
      cellphoneNumber: parent.cellphoneNumber,
      relationToChild: parent.relationToChild,
      nationalRegistryNumber: parent.nationalRegistryNumber
    });
  }

  createEmptyParent(): FormGroup {
    return this.formBuilder.group({
      firstName: '',
      lastName: '',
      email: '',
      cellphoneNumber: '',
      relationToChild: '',
      nationalRegistryNumber: ''
    });
  }

  addParent(): void {
    this.parents = this.registerForm.get('parents') as FormArray;
    this.parents.push(this.createEmptyParent());
  }

  removeParent(index: number) {
    this.parents.removeAt(index);
  }
}
