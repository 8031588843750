import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Child} from '../../models/child';
import {Address} from '../../models/address';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'child',
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  template: `
    <div [formGroup]="child" class="child">
      <div class="child-header">
          <h3>Gegevens Kind:
            <span *ngIf="childData?.member"
                  matTooltip="U kan de gegevens van een reeds ingeschreven kind niet meer aanpassen.
                                U kan dit wel nog laten doen bij een van onze inschrijvers">reeds ingeschreven <span class="material-icons">help</span></span>
          </h3>
        <button mat-flat-button color="warn"
                *ngIf="childIndex > 0 && !childData?.member" type="button"
                (click)="removeChild(childIndex)">Verwijder kind</button>
        </div>
      <div class="child-content">
        <mat-form-field>
          <mat-label>Voornaam</mat-label>
          <input matInput placeholder="Voornaam" formControlName="firstName" required [readonly]="childData?.member">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Achternaam</mat-label>
          <input matInput placeholder="Achternaam" formControlName="lastName" required [readonly]="childData?.member">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Huisdokter</mat-label>
          <input matInput placeholder="Huisdokter" formControlName="familyDoctor" required [readonly]="childData?.member">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Geboortedatum</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="DD-MM-YYYY" formControlName="dateOfBirth"
                 required [readonly]="childData?.member">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker [disabled]="childData?.member"></mat-datepicker>
        </mat-form-field>
        <mat-label *ngIf="childData" matTooltip="U kunt op Saenhoeve navragen of u van groep kan veranderen">
          Groep: {{childData.ageGroup.name}}
        </mat-label>

        <addresses [child]="child" [addressesData]="getAddressData()" [childIsMember]="childData?.member"></addresses>

        <mat-form-field>
          <mat-label>Allergiën</mat-label>
          <input matInput placeholder="Welke allergiën zijn aanwezig waar wij rekening mee moeten houden?"
                 formControlName="allergiesNote" [readonly]="childData?.member">
        </mat-form-field>
        <section class="maaltijden">
          <label class="example-margin">Voorkeur maaltijden:</label>
          <mat-radio-group formControlName="mealPreference" required>
            <mat-radio-button class="example-margin" value="NO_PREFERENCE" [checked]="childData?.mealPreference === 1 || childData?.mealPreference == undefined" [disabled]="mealPreferenceDisabled(1)">Geen voorkeur</mat-radio-button>
            <mat-radio-button class="example-margin" value="HALAL" [checked]="childData?.mealPreference === 2" [disabled]="mealPreferenceDisabled(2)">Halal</mat-radio-button>
            <mat-radio-button class="example-margin" value="VEGETARIAN" [checked]="childData?.mealPreference === 3" [disabled]="mealPreferenceDisabled(3)">Vegetarisch</mat-radio-button>
          </mat-radio-group>
        </section>
        <section class="schooltoeslag">
          <label class="example-margin">Heeft u recht op schooltoeslag?</label>
          <mat-radio-group formControlName="tuition">
            <mat-radio-button class="example-margin" value="false" [checked]="!childData?.tuition" [disabled]="childData?.tuition && childData?.member">GEEN recht op schooltoeslag
            </mat-radio-button>
            <mat-radio-button class="example-margin" value="true" [checked]="childData?.tuition" [disabled]="!childData?.tuition && childData?.member">WEL recht op schooltoeslag
            </mat-radio-button>
          </mat-radio-group>
        </section>
        <mat-form-field>
          <mat-label>Rijksregisternummer</mat-label>
          <input matInput placeholder="LET OP: indien u uw kosten wilt inbrengen aan de belastingen MOET u dit veld invullen."
                 formControlName="nationalRegistryNumber" [readonly]="childData?.member">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Extra opmerking</mat-label>
          <input matInput placeholder="Heeft u een extra opmerking dat u graag wilt mee delen? Gelieve allergiën niet hier in te vullen maar in het bedoelde vak hierboven."
                 formControlName="medicalNote" [readonly]="childData?.member">
        </mat-form-field>
      </div>
    </div>
  `,
  styleUrls: ['children.component.scss']
})
export class ChildComponent implements OnInit {

  @Input()
  child: FormGroup;

  @Input()
  childIndex: number;

  @Input()
  childData: Child;

  @Output()
  childRemoved: EventEmitter<number> = new EventEmitter();

  ngOnInit(): void {
    if (this.childData) {
      const birthDate = moment(this.childData.dateOfBirth, 'DD-MM-YYYY');
      this.child.controls.dateOfBirth.setValue(birthDate);
    }
  }

  removeChild(index: number) {
    this.childRemoved.emit(index);
  }

  getAddressData(): Address[] {
    if (this.childData && this.childData.addresses.length > 0) {
      return this.childData.addresses;
    }

    return [];
  }

  mealPreferenceDisabled(index: number) {
    return this.childData
      && this.childData.member
      && this.childData.mealPreference > 0
      && this.childData.mealPreference !== index;
  }
}
