import {Injectable} from "@angular/core";
import {Account} from "../../models/account";
import {Observable, of} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class AccountService {

  constructor(private readonly httpClient: HttpClient) {
  }

  findAccounts(): Observable<Account[]> {
    const url = '/api/account';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    });

    return this.httpClient.get<Account[]>(url, {headers: headers});
  }

  registerNewAccount(account: Account): Observable<any> {
    const url = '/api/account';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.httpClient.post<Response>(url, account, {headers: headers, observe: 'response'});
  }

  updateAccount(account: Account): Observable<any> {
    const url = `/api/account/${account.id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    });

    return this.httpClient.put<Response>(url, account, {headers: headers, observe: 'response'});
  }

  getLoggedInAccount(): Observable<Account> {
    const url = '/api/account/current';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    });

    return this.httpClient.get<Account>(url, {headers: headers});
  }
}
