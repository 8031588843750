import {Component, OnInit} from '@angular/core';
import {Account} from '../../models/account';
import {AccountService} from '../../services/account/account.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {MessagesService} from '../../services/errorMessage/messages.service';
import {HttpErrorResponse} from "@angular/common/http";
import {DatePatterns} from "../../models/DatePatterns";

@Component({
  selector: 'registration',
  template: `
    <mat-toolbar>
      <span>Registratie</span>
    </mat-toolbar>
    <mat-card class="my-card">
      <mat-card-content>
        <mat-card-content>
          <form [formGroup]="registerForm">
            <mat-form-field>
              <mat-label>Gebruikersnaam/email</mat-label>
              <input matInput placeholder="Gebruikersnaam/email" formControlName="username" required>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Wachtwoord</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
<!--                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>-->
              </button>
            </mat-form-field>

            <parents [registerForm]="this.registerForm"></parents>

            <children [registerForm]="this.registerForm"></children>

            <mat-checkbox class="terms-conditions" formControlName="acceptedTermsConditions">Door mijn kinderen in te schrijven ga ik akkoord met de <a href="https://www.saenhoeve.be/wp-content/uploads/2024/05/2024-afsprakennota-of-reglement.pdf" target="_blank">voorwaarden op de website</a>!</mat-checkbox>

           </form>
        </mat-card-content>
      </mat-card-content>

      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="register()" color="primary" disabled="{{!this.registerForm.value.acceptedTermsConditions}}">REGISTREER
        </button>
      </mat-card-actions>

    </mat-card>
  `,
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  registerForm: FormGroup;
  hide = true;
  addresses: FormArray;
  account: Account;

  constructor(private readonly accountService: AccountService, private readonly formBuilder: FormBuilder,
              private readonly router: Router, private _snackBar: MatSnackBar,
              private readonly authenticationService: AuthenticationService, private readonly messagesService: MessagesService) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: '',
      password: '',
      acceptedTermsConditions: false
    });
  }

  register(): void {

    if (this.registerForm.invalid) {
      this.messagesService.showInvalidForm();
      return;
    }

    this.fillAccount();

    const resultRef = this.accountService.registerNewAccount(this.account).subscribe(
      response => {
        this.handleActivationResponse(response);
        resultRef.unsubscribe();
      },
      (error: HttpErrorResponse) => this.handleError(error)
    );
  }

  private fillAccount() {
    this.account = this.registerForm.value;
    this.account.children.forEach(child => {
      child.dateOfBirth = DatePatterns.getCorrectDatePattern(child.dateOfBirth);
    });
    this.account.user = {
      id: 0, roles: [],
      username: this.registerForm.value.username,
      password: this.registerForm.value.password,
      passwordReset: false,
      acceptedTermsConditions: this.registerForm.value.acceptedTermsConditions
    };
  }

  private handleActivationResponse(response: any) {
    this.messagesService.showRegistrationSucces();
    this.authenticationService.login(this.account.user.username, this.account.user.password)
      .subscribe(data => {
        this.router.navigate(['/overview']);
      }, (error: HttpErrorResponse) => {
        this.router.navigate(['/login']);
      });
  }

  private handleError(error: any) {
    this.messagesService.getAndShowErrorMessage(error);
  }
}
